<template>
  <div class="product-filter-container">
    <sweet-modal ref="modal" overlay-theme="dark" title="필터" @close="handleClose" blocking>
      <div class="modal-container" ref="modalContainer">
        <div class="modal-wrapper">
          <div>
            <div class="subtitle6 main">지원 환경</div>
            <div class="categories-flex">
              <div class="modal-content-item" v-for="support in env_list" :key="`tag-${support.id}`">
                <input type="checkbox" :value="support.id" v-model="support.checked" :id="`tag-${support.id}`" />
                <label :for="`tag-${support.id}`" class="unselect">{{ support.name }}</label>
              </div>
            </div>
          </div>
          <div>
            <div class="subtitle6 main">구축 비용</div>
            <c-input-range-dual v-model="params.price" :min="0" :max="100000000" unit="원" />
          </div>
          <div class="platform-type-wrapper">
            <div class="flex-align" style="gap: 4px;">
              <div class="subtitle6 main">플랫폼파이 솔루션 기반</div>
              <tool-tip-new
                ref="tooltip1"
                :iconSize="20"
                icon="u_question-circle"
                title="플랫폼파이 솔루션이란?"
                text="개발자 없이도 플랫폼 서비스를 5분 안에 개설할 수 있는 솔루션입니다. 서비스 운영에 필요한 관리자 사이트 등이 포함된 운영 솔루션이 제공됩니다."
              />
            </div>
            <div class="theme-type-wrapper unselect" @click="categories.theme.checked = !categories.theme.checked">
              <div class="flex-align" style="gap: 8px;">
                <div class="check-title-wrapper">
                  <input type="checkbox" v-model="categories.theme.checked" :id="`theme-${categories.theme.id}`" />
                  <label :for="`theme-${categories.theme.id}`" class="weight-500 unselect">{{
                    categories.theme.name
                  }}</label>
                </div>
                <div class="tag-label body6-medium primary bg-primary50">디자인 편집 빌더 제공</div>
                <div class="tag-label body6-medium success bg-success50">바로개설</div>
                <div class="tag-label body6-medium subcolor2 bg-subcolor50">무료체험</div>
              </div>
              <div class="body6 sub3" style="white-space: pre-line; word-break: keep-all;">
                월단위로 구독하여 단기로 이용할 수 있는 템플릿입니다.<br />
                디자인 편집 빌더가 제공되어 개발자 없이도 직접 수정할 수 있습니다.
              </div>
            </div>
            <div class="theme-type-wrapper unselect" @click="categories.skin.checked = !categories.skin.checked">
              <div class="check-title-wrapper">
                <input type="checkbox" v-model="categories.skin.checked" :id="`skin-${categories.skin.id}`" />
                <label :for="`skin-${categories.skin.id}`" class="weight-500 unselect">{{
                  categories.skin.name
                }}</label>
              </div>
              <div class="body6 sub3" style="white-space: pre-line; word-break: keep-all;">
                한 번만 결제하여 기간제한 없이 이용하는 템플릿입니다.
              </div>
            </div>
          </div>
          <div>
            <div class="subtitle6 main">주요 기능</div>
            <div class="categories">
              <div class="modal-content-item" v-for="feature in features" :key="`tag-${feature.id}`">
                <input type="checkbox" :value="feature.id" v-model="feature.checked" :id="`feature-${feature.id}`" />
                <label :for="`feature-${feature.id}`" class="unselect">{{ feature.name }}</label>
              </div>
            </div>
          </div>
          <div>
            <div class="flex-align" style="gap: 4px;">
              <div class="subtitle6 main">플랫폼파이 솔루션 기반</div>
              <tool-tip-new
                ref="tooltip2"
                :iconSize="20"
                icon="u_question-circle"
                portal
                :position="{ position: 'fixed', top: '80%', left: '41%' }"
                title="소스코드란?"
                text="프리랜서 혹은 인하우스 디자이너, 개발자들이 직접 수정개발 할 수 있게 소스코드로 제공되는 템플릿입니다. 기제작된 결과물을 기반으로 빠르고 효율적으로 제작할 수 있습니다. "
              />
            </div>
            <div class="categories-flex">
              <div class="flex-align" style="gap: 8px;">
                <input type="checkbox" v-model="categories.source.checked" :id="`source-${categories.source.id}`" />
                <label :for="`source-${categories.source.id}`" class="unselect">{{ categories.source.name }}</label>
              </div>
            </div>
          </div>
        </div>
        <template v-if="!isMobile">
          <div class="btn-wrapper">
            <button class="button is-sub body2-bold" style="height: 52px;" @click="reset">초기화</button>
            <button class="button is-primary body2-bold" style="height: 52px;" @click="apply">적용</button>
          </div>
        </template>
        <template v-else>
          <portal v-if="bottomBtn" to="btn-modal">
            <div class="btn-wrapper-m">
              <button class="button is-sub body2-bold" style="height: 52px;" @click="reset">초기화</button>
              <button class="button is-primary body2-bold" style="height: 52px;" @click="apply">적용</button>
            </div>
          </portal>
        </template>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import CInputRangeDual from '@/components/module/CInputRangeDual.vue';
import ToolTipNew from '../component/ToolTipNew.vue';

export default {
  name: 'ProductFilterModal',
  mixins: [],
  components: {
    SweetModal,
    CInputRangeDual,
    ToolTipNew,
  },
  props: {},
  data() {
    return {
      bottomBtn: false,
      params: {
        price: [1000000, 100000000],
      },
      categories: {
        theme: {
          id: 59,
          name: '테마',
          checked: false,
        },
        skin: {
          id: 60,
          name: '스킨',
          checked: false,
        },
        source: {
          id: 70,
          name: '소스코드 템플릿 보기',
          checked: false,
        },
      },
      env_list: [
        { id: 0, name: 'Mobile Web', checked: false },
        { id: 1, name: 'PC Web', checked: false },
        { id: 2, name: 'Android', checked: false },
        { id: 3, name: 'iOS', checked: false },
      ],
      features: [
        { id: 0, name: 'PG결제', checked: false },
        { id: 1, name: '커뮤니티', checked: false },
        { id: 2, name: '위치 기반 서비스', checked: false },
        { id: 3, name: '라이브', checked: false },
        { id: 4, name: 'AI', checked: false },
        { id: 5, name: '크롤링/스크래핑', checked: false },
        { id: 6, name: 'VR', checked: false },
        { id: 7, name: '전자계약', checked: false },
      ],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    init() {
      if (this.$store.state.filtered.length === 0) {
        this.reset();
      } else {
        this.$store.state.filtered.forEach((filter) => {
          switch (filter.key) {
            case 'support_env':
              this.env_list.forEach((env) => {
                env.checked = filter.value.includes(env.name);
              });
              break;
            case 'price':
              this.params.price = [filter.start, filter.end];
              break;
            case 'category':
              {
                const categoryIds = filter.value.split(',').map(Number);
                this.categories.theme.checked = categoryIds.includes(this.categories.theme.id);
                this.categories.skin.checked = categoryIds.includes(this.categories.skin.id);
                this.categories.source.checked = categoryIds.includes(this.categories.source.id);
              }
              break;
            case 'features':
              this.features.forEach((feature) => {
                feature.checked = filter.value.includes(feature.name);
              });
              break;
          }
        });
      }
    },
    open() {
      this.init();
      this.$nextTick(() => {
        this.$refs.modal.open();
        document.documentElement.style.overflow = 'hidden';
        // ref를 사용하여 modal-container의 스크롤을 맨 위로 이동
        this.$nextTick(() => {
          if (this.$refs.modalContainer) {
            this.$refs.modalContainer.scrollTop = 0;
          }
        });
        this.bottomBtn = true;
      });
    },
    close() {
      document.documentElement.style.overflow = '';
      if (this.$refs.tooltip1) {
        this.$refs.tooltip1.vTooltip = false;
      }
      if (this.$refs.tooltip2) {
        this.$refs.tooltip2.vTooltip = false;
      }
      this.bottomBtn = false;
      this.$refs.modal.close();
    },
    reset() {
      this.categories.theme.checked = false;
      this.categories.skin.checked = false;
      this.categories.source.checked = false;
      this.env_list.forEach((env) => {
        env.checked = false;
      });
      this.features.forEach((feature) => {
        feature.checked = false;
      });
      this.params.price = [1000000, 100000000];
    },
    apply() {
      const params = [];

      // 지원 환경 처리
      const selectedEnv = this.env_list.filter((env) => env.checked).map((env) => env.name);
      if (selectedEnv.length > 0) {
        params.push({
          key: 'support_env',
          value: selectedEnv,
        });
      }

      // 가격 범위 처리
      if (this.params.price[0] !== 0 || this.params.price[1] !== 0) {
        params.push({
          key: 'price',
          start: this.params.price[0],
          end: this.params.price[1],
        });
      }

      // 카테고리 처리
      const selectedCategories = [];
      if (this.categories.theme.checked) selectedCategories.push(this.categories.theme.id);
      if (this.categories.skin.checked) selectedCategories.push(this.categories.skin.id);
      if (this.categories.source.checked) selectedCategories.push(this.categories.source.id);
      if (selectedCategories.length > 0) {
        params.push({
          key: 'category',
          value: selectedCategories.join(','),
        });
      }

      // 주요 기능 처리
      const selectedFeatures = this.features.filter((feature) => feature.checked).map((feature) => feature.name);
      if (selectedFeatures.length > 0) {
        params.push({
          key: 'features',
          value: selectedFeatures,
        });
      }
      const hasParams = params.length > 0;
      const hasFiltered = this.$store.state.filtered.length > 0;

      if (hasParams || hasFiltered) {
        this.$store.commit('setFiltered', hasParams ? params : []);
        this.$emit('apply', hasParams ? params : []);
      }
      this.close();
    },
    handleClose() {
      document.documentElement.style.overflow = '';
      if (this.$refs.tooltip1) {
        this.$refs.tooltip1.vTooltip = false;
      }
      if (this.$refs.tooltip2) {
        this.$refs.tooltip2.vTooltip = false;
      }
      this.bottomBtn = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
input[type="checkbox"]
    appearance none
    width 20px !important
    height 20px
    border 2px solid $gray1
    border-radius 4px
    background-color white
    display flex
    align-items center
    justify-content center
    cursor pointer
    margin 0 !important
    &:checked
      background-color $primary
      border-color $primary
      &::after
        content '\e5ca'
        color white
        font-size 14px
        font-weight 700
        font-family 'Material Icons'

      margin 0
      width fit-content
      border 1px solid $gray1
      border-radius 4px
      padding 4px
    label
      font-size 14px
      color $sub

.modal-container
  display flex
  flex-direction column
.modal-wrapper
  display flex
  flex-direction column
  gap 40px
  padding 24px

.categories-flex
  display flex
  align-items center
  gap 8px
  margin-top 12px

.categories
  display grid
  grid-template-columns repeat(2, 1fr)
  row-gap 8px
  column-gap 16px
  margin-top 12px
.modal-content-item
  display flex
  align-items center
  gap 8px

.platform-type-wrapper
  display flex
  flex-direction column
  gap 12px

.theme-type-wrapper
  display flex
  flex-direction column
  padding 16px
  border-radius 8px
  border 1px solid $gray1
  gap 10px
.theme-type-wrapper:hover
  border-color $primary

.check-title-wrapper
  display flex
  align-items center
  gap 12px
.tag-label
  display flex
  align-items center
  justify-content center
  padding 1px 6px
  border-radius 2px
.btn-wrapper
  padding 24px
  display grid
  grid-template-columns 120px 1fr
  border-top 1px solid $gray2
  align-items center
  justify-content center
  gap 8px

.btn-wrapper-m
  position fixed
  bottom 0
  left 0
  padding 12px 16px
  display grid
  grid-template-columns 120px 1fr
  border-top 1px solid $gray2
  align-items center
  justify-content center
  gap 8px
  width 100%
  background white
  z-index 9999

@media (max-width: 425px)
  .modal-container
    overflow auto
    height calc(100vh - 64px)
  .modal-wrapper
    padding 16px
    margin-bottom 180px
  .categories-flex
    flex-wrap wrap
    gap 8px 24px
  .btn-wrapper
    padding 16px
  .tag-label
    font-size 11px !important
</style>
<style lang="stylus">
.product-filter-container
  .sweet-modal .sweet-content
    padding 0 !important
  .sweet-modal-overlay
    -webkit-perspective initial
  .sweet-modal.is-alert
    width 600px
    max-width 600px
    overflow hidden
  .sweet-modal.is-alert .sweet-content
    padding 0
  @media (max-width: 425px)
    .sweet-modal
      border-radius 0 !important
    .sweet-modal.theme-light.has-title.has-content.is-mobile-fullscreen.is-visible
      overflow-y hidden
    .sweet-modal .sweet-title
      display flex
      align-items center
      padding 16px
      font-size 18px !important
      font-weight 700 !important
      line-height 24px !important
    .sweet-modal.is-alert
      width 100%
      max-width 100%
    .sweet-modal .sweet-box-actions
      top 10px
      right 10px
</style>
