<template>
  <div
    class="c-input-range-dual-container
              c-root
              position-relative
              full-width"
  >
    <div>
      <div style="padding: 0 10px">
        <vue-slider
          ref="slider"
          v-model="inputValue"
          :min="parseInt(min)"
          :max="parseInt(max)"
          :marks="computedMarks"
          :included="true"
          :height="4"
          :dotSize="20"
          :bgStyle="bgStyle"
          :dotStyle="dotStyle"
          :processStyle="processStyle"
          :sliderStyle="sliderStyle"
          :tooltipStyle="tooltipStyle"
          tooltip="none"
          @change="onChange"
        >
          <template v-slot:dot>
            <div :style="dotStyle"></div>
          </template>
        </vue-slider>
      </div>
      <!-- Label Number -->
      <!-- <div class="unit" :style="unitStyle">{{ selectedLabel }}</div> -->

      <!-- 새로 추가된 input 필드 -->
      <div class="input-fields">
        <div class="input-group">
          <span class="body3 sub3 input-prefix">최소</span>
          <div class="input-wrapper">
            <input
              class="range-input sub"
              type="text"
              v-model="minDisplayValue"
              @input="onMinInput"
              @blur="onMinBlur"
              :min="parseInt(min)"
            />
          </div>
        </div>
        <span class="body3">~</span>
        <div class="input-group">
          <span class="body3 sub3 input-prefix">최대</span>
          <div class="input-wrapper">
            <input
              class="range-input sub"
              type="text"
              v-model="maxDisplayValue"
              @input="onMaxInput"
              @blur="onMaxBlur"
              :max="parseInt(max)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSlider from 'vue-slider-component';
import FormMixin from '../../mixins/FormMixin';
import 'vue-slider-component/theme/antd.css';

export default {
  name: 'c-input-rangeDual',

  components: {
    vueSlider,
  },

  mixins: [FormMixin],

  props: {
    value: {
      type: Array,
      required: true,
    },
    min: {
      type: [Number, String],
      required: true,
    },
    max: {
      type: [Number, String],
      required: true,
    },
    unit: {
      type: String,
      required: false,
    },
  },

  mounted() {
    if (this.value) {
      this.onChange();
    }
  },

  watch: {
    value: {
      handler(newValue) {
        this.inputValue = newValue;
      },
      deep: true,
      immediate: true,
    },
    inputValue: {
      handler(newValue) {
        this.minDisplayValue = `${this.formatNumber(newValue[0])}${this.minUnit}`;
        this.maxDisplayValue = `${this.formatNumber(newValue[1])}${this.maxUnit}`;
      },
      deep: true,
      immediate: true,
    },
    minDisplayValue(newValue) {
      const numericValue = this.parseNumber(newValue);
      if (!isNaN(numericValue) && numericValue >= parseInt(this.min)) {
        this.$set(this.inputValue, 0, numericValue);
        this.value[0] = numericValue;
      }
    },
    maxDisplayValue(newValue) {
      const numericValue = this.parseNumber(newValue);
      if (!isNaN(numericValue) && numericValue <= parseInt(this.max)) {
        this.$set(this.inputValue, 1, numericValue);
        this.value[1] = numericValue;
      }
    },
  },

  data() {
    return {
      inputValue: this.value,
      minDisplayValue: '',
      maxDisplayValue: '',
    };
  },

  computed: {
    minUnit() {
      return parseInt(this.inputValue[0]) === 0 ? '원' : '원 대';
    },
    maxUnit() {
      return parseInt(this.inputValue[1]) === 100000000 ? '원 이하' : '원 대';
    },
    computedMax() {
      let max = this.max;
      if (parseInt(this.inputValue[1]) <= 10000000) {
        max = 10000000;
      }
      return max;
    },
    computedInterval() {
      return 1000000; // 백만 단위로 이동
    },
    bgStyle() {
      return '#FFFFFF';
    },
    dotStyle() {
      return {
        border: '1px solid #FF6600',
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        backgroundColor: 'white',
      };
    },
    processStyle() {
      return { backgroundColor: '#FF6600' };
    },
    sliderStyle() {
      return { backgroundColor: '#FF6600' };
    },
    tooltipStyle() {
      let processColor = '#FF6600';
      return {
        background: processColor,
        border: `1px solid ${processColor}`,
      };
    },
    selectedLabel() {
      return `${this.currencyKor(this.inputValue[0])} ~ ${this.currencyKor(this.inputValue[1])}`;
    },
    unitStyle() {
      return '#333333';
    },
    computedMarks() {
      const marks = {};
      let currentValue = parseInt(this.min);
      while (currentValue <= parseInt(this.max)) {
        marks[currentValue] = '';
        currentValue += this.getInterval(currentValue);
      }
      return marks;
    },
  },

  methods: {
    onChange() {
      this.$emit('update:value', this.inputValue);
      this.$emit('update:selectedValue', this.selectedLabel);
    },
    getLabel(value) {
      let val = parseInt(value);
      if (val === 0) return `0${this.unit ? this.unit : ''}`;

      let txt = '';
      let unit = 1000000000000;

      let unitTextArray = ['조 ', '억 ', '만'];
      let utIdx = 0;
      while (unit >= 10000) {
        let divideVal = Math.floor(val / unit);
        if (divideVal >= 1) {
          txt += divideVal + unitTextArray[utIdx];
          val = val - divideVal * unit;
        }
        utIdx++;
        unit /= 10000;
      }
      if (val > 0) {
        txt += ` ${val}`;
      }
      return `${txt}${this.unit ? this.unit : ''}`;
    },
    formatNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },

    parseNumber(value) {
      return parseInt(value.replace(/[^0-9]/g, ''));
    },

    onMinInput(event) {
      const value = event.target.value.replace(/[^0-9]/g, '');
      const numericValue = parseInt(value);
      if (!isNaN(numericValue) && numericValue >= parseInt(this.min)) {
        this.$set(this.inputValue, 0, numericValue);

        // maxDisplayValue나 inputValue[1]이 0인 경우 this.max로 설정
        if (this.inputValue[1] === 0 || parseInt(this.maxDisplayValue) === 0) {
          this.$set(this.inputValue, 1, parseInt(this.max));
          this.maxDisplayValue = this.formatNumber(parseInt(this.max)) + this.maxUnit;
        }
        // 입력된 값이 현재 최대값보다 크면 최대값도 함께 조정
        else if (numericValue > this.inputValue[1]) {
          this.$set(this.inputValue, 1, numericValue);
          this.maxDisplayValue = this.formatNumber(numericValue) + this.maxUnit;
        }
      }
      this.minDisplayValue = this.formatNumber(this.inputValue[0]) + this.minUnit;
    },
    onMaxInput(event) {
      const value = event.target.value.replace(/[^0-9]/g, '');
      this.maxDisplayValue = value ? `${value}${this.maxUnit}` : '';
      const numericValue = parseInt(value);
      if (!isNaN(numericValue) && numericValue <= parseInt(this.max) && numericValue >= this.inputValue[0]) {
        this.$set(this.inputValue, 1, numericValue);
      }
    },
    onMinBlur() {
      const value = this.parseNumber(this.minDisplayValue);
      if (!isNaN(value) && value >= parseInt(this.min) && value <= this.inputValue[1]) {
        this.$set(this.inputValue, 0, value);
      }
      this.minDisplayValue = `${this.formatNumber(this.inputValue[0])}${this.minUnit}`;
    },
    onMaxBlur() {
      const value = this.parseNumber(this.maxDisplayValue);
      if (!isNaN(value) && value <= parseInt(this.max) && value >= this.inputValue[0]) {
        this.$set(this.inputValue, 1, value);
      }
      this.maxDisplayValue = `${this.formatNumber(this.inputValue[1])}${this.maxUnit}`;
    },
    updateSlider() {
      if (this.$refs.slider) {
        if (typeof this.$refs.slider.refresh === 'function') {
          this.$refs.slider.refresh();
        } else if (typeof this.$refs.slider.refresh === 'object') {
          this.$refs.slider.refresh();
        } else {
          // 슬라이더를 강제로 다시 렌더링
          const currentValue = [...this.inputValue];
          this.inputValue = [];
          this.$nextTick(() => {
            this.inputValue = currentValue;
          });
        }
      }
    },
    getInterval(value) {
      return value < 10000000 ? 1000000 : 10000000;
    },
  },
};
</script>

<style lang="stylus" type="stylus" scoped>
.unit
  font-size 0.9rem
  margin-top 4px
  text-align right

.input-fields
  display flex
  justify-content center
  align-items center
  margin-top 12px
  gap 8px

.input-group
  position relative
  display flex
  align-items center
  width 100%

.input-wrapper
  position relative
  flex-grow 1

input
  width 100%
  height 48px
  padding 12px 12px 12px 46px
  border 1px solid #E3E3E3
  border-radius 8px
  text-align left

.range-input
  width 100%
  padding-right 40px // suffix를 위한 공간
  text-align right

.input-prefix
  position absolute
  left 16px
  top 50%
  transform translateY(-50%)
  font-weight 400
  z-index 1

.input-suffix
  position absolute
  right 12px
  top 50%
  transform translateY(-50%)
  background-color white
  padding-left 4px

@media (max-width: 768px)
  .range-input
    padding-left 0
    padding-right 8px

  .input-prefix
    display none
</style>

<style>
.vue-slider-mark {
  display: none;
}
</style>
