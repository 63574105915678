<template>
  <div class="position-relative" :style="{ width: iconSize + 'px', height: iconSize + 'px' }">
    <img
      class="svg-sub4 icon-info unselect"
      :src="`/static/icon/${icon}.svg`"
      :style="{ width: iconSize + 'px', height: iconSize + 'px' }"
      @click="vTooltip = !vTooltip"
    />
    <template v-if="portal">
      <portal to="tooltip">
        <div class="box-tooltip" v-if="vTooltip" :style="portalStyle">
          <div class="flex-align">
            <div class="subtitle6" style="flex:1">{{ title }}</div>
            <i class="material-icons unselect size-20 sub4" @click="vTooltip = false">close</i>
          </div>
          <div class="body6-medium">{{ text }}</div>
        </div>
      </portal>
    </template>
    <template v-else>
      <div class="box-tooltip" v-if="vTooltip" :style="`width:${computedWidth}px; top:${computedTop}px`">
        <div class="flex-align">
          <div class="subtitle6" style="flex:1">{{ title }}</div>
          <i class="material-icons unselect size-20 sub4" @click="vTooltip = false">close</i>
        </div>
        <div class="body6-medium">{{ text }}</div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'ToolTip',
  props: {
    icon: {
      type: String,
      default: 'info',
    },
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    iconSize: {
      type: Number,
      default: 24,
    },
    portal: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Object,
      default: () => ({ position: 'fixed', top: 0, left: 0 }),
    },
  },
  created() {},
  computed: {
    computedWidth() {
      return 300;
    },
    computedTop() {
      return this.iconSize + 4;
    },
    portalStyle() {
      let deco = {
        width: this.computedWidth + 'px',
        position: this.position.position,
        top: this.position.top,
        left: this.position.left,
      };
      return deco;
    },
  },
  data() {
    return {
      vTooltip: false,
    };
  },
  methods: {},
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'
.icon-info
  color $sub5
  vertical-align text-bottom

.box-tooltip
  display flex
  flex-direction column
  gap 8px
  background-color white
  color $main
  position absolute
  left 0
  padding 16px
  border-radius 12px
  box-shadow 0px 0px 30px 0px rgba(0, 0, 0, 0.12)
  z-index 9999
</style>
