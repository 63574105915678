<template>
  <div class="category-product-wrapper">
    <div v-if="isMobile" class="category-slider" :class="$route.path.includes('template') ? 'stick-header-m' : ''">
      <div class="category-slider-wrapper-m">
        <div
          class="category-slider-item-m"
          v-for="(item, idx) in categories"
          :key="`category-${idx}`"
          :ref="`category-${item.id}`"
          :class="{ selected: selectedCategory === item.id || (searchCategory && searchCategory.id === item.id) }"
          @click="selectCategory(item)">
          <img :src="selectedCategory === item.id ? item.img_after : getImageSrc(item)" />
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <CategorySlider v-else :categories="categories" @selectCategory="selectCategory" :seleted="searchCategory" />
    <div class="proudct-filter-wrapper">
      <div class="product-filter-item">
        <div class="flex-align" style="gap: 8px">
          <button
            class="button flex-align body4"
            :class="filterCount > 0 ? 'is-main' : 'is-gray'"
            style="height: 40px; gap: 8px; padding: 11px 12px"
            @click="openProductFilterModal">
            <img style="width: 18px; height: 18px" src="/static/icon/u_sliders.svg" />
            <div>필터</div>
            <div v-if="filterCount > 0" class="filter-count">{{ filterCount }}</div>
          </button>
          <button
            class="button body4"
            :class="freeFilterBtn ? 'is-dark' : 'is-gray'"
            style="height: 40px; padding: 2px 12px"
            @click="clickFreeSetFilter">
            💡무료체험
          </button>
        </div>
      </div>
      <div class="product-filter-container">
        <filtered-list
          v-if="filtered.length > 0"
          :filtered="filtered"
          @listLength="updateFilterCount"
          @reset="resetFilter"
          @removeFilter="removeFiltered"></filtered-list>
      </div>
      <div v-if="tempProducts.length > 0" class="product-list-wrapper">
        <card-product-temp
          v-for="card in tempProducts"
          :key="`card-${card.id}`"
          :card="card"
          :device="isMobile ? 'mobile' : 'pc'"
          @clickScreenshot="clickScreenshot"></card-product-temp>
      </div>
      <div v-else class="product-list-empty">
        <div class="body3 sub4 text-center">
          검색 결과가 없습니다.<br />아래의 맞춤 제작이나 템플릿 찾기로 나에게 맞는 템플릿을 찾아보세요.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CategorySlider from './CategorySlider';
import FilteredList from './FilteredList';
import CardProductTemp from './CardProductTemp';

export default {
  name: 'TemplateFilterList',
  components: {
    CategorySlider,
    FilteredList,
    CardProductTemp,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    tempProducts: {
      type: Array,
      required: true,
    },
    searchCategory: {
      type: Object,
      default: null,
    },
    currentImages: {
      type: Object,
      default: () => ({}),
    },
    filteredCategory: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterCount: 0,
      freeFilterBtn: false,
      filtered: [],
      selectedCategory: 0,
      observer: null,
    };
  },
  mounted() {
    // 이렇게 해서 들어오는 필터는 새로고침시 제거 됨
    if (this.filteredCategory && this.filteredCategory.length > 0) {
      this.filtered = this.filteredCategory;
    }
    this.setupMutationObserver();
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    clickScreenshot(card) {
      this.$emit('clickScreenshot', card);
    },
    selectCategory(category) {
      if (this.isMobile) {
        console.log(this.selectedCategory, category.id);
        if (this.selectedCategory !== category.id) {
          this.selectedCategory = category.id;
          this.$emit('selectCategory', category);
        } else {
          return;
        }
        this.$nextTick(() => {
          const element = this.$refs[`category-${category.id}`]?.[0];
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
          }
        });
      } else {
        this.$emit('selectCategory', category);
      }
    },
    openProductFilterModal() {
      this.$emit('openProductFilterModal');
    },
    clickFreeSetFilter() {
      this.freeFilterBtn = !this.freeFilterBtn;
      // 무료체험 필터 로직 구현
      this.$emit('clickFreeSetFilter', this.freeFilterBtn);
    },
    updateFilterCount(val) {
      this.filterCount = val;
    },
    resetFilter() {
      this.filtered = [];
      this.$store.commit('setFiltered', this.filtered);
      this.$emit('resetFilter', this.filtered);
    },
    removeFiltered(item) {
      const index = this.filtered.findIndex((i) => i.key === item.key);
      if (index !== -1) {
        const delFilter = { ...this.filtered[index] };

        if (delFilter.value && Array.isArray(delFilter.value)) {
          delFilter.value = delFilter.value.filter((i) => i !== item.value);
          this.$set(this.filtered, index, delFilter);
        } else if (delFilter.key === 'category') {
          let categoryId = this.getCategoryId(item.value);
          delFilter.value = delFilter.value
            .split(',')
            .filter((i) => i !== categoryId)
            .join(',');
          this.$set(this.filtered, index, delFilter);
        } else {
          this.filtered.splice(index, 1);
        }
        if (delFilter.value === '' || (Array.isArray(delFilter.value) && delFilter.value.length === 0)) {
          this.filtered.splice(index, 1);
        }
      }

      this.$store.commit('setFiltered', this.filtered);
      // 필터 제거 로직 구현
      this.$emit('removeFiltered', this.filtered);
    },
    getCategoryId(key) {
      const categories = {
        테마: '59',
        스킨: '60',
        '소스코드 템플릿': '70',
      };
      return categories[key];
    },
    getImageSrc(item) {
      return this.currentImages[item.id] || item.img_before;
    },
    scrollToCategory(categoryId) {
      this.$nextTick(() => {
        const element = this.$refs[`category-${categoryId}`]?.[0];
        const container = this.$el.querySelector('.category-slider');
        if (element && container) {
          const scrollOptions = {
            left: element.offsetLeft - container.clientWidth / 2 + element.clientWidth / 2,
            behavior: 'smooth',
          };
          container.scrollTo(scrollOptions);
        }
      });
    },
    setupMutationObserver() {
      const config = { childList: true, subtree: true };

      this.observer = new MutationObserver(() => {
        if (this.searchCategory && this.isMobile) {
          this.scrollToCategory(this.searchCategory.id);
        }
      });

      this.observer.observe(this.$el, config);
    },
  },
  watch: {
    '$store.state.filtered'(newVal) {
      this.filtered = newVal;
      if (this.filtered.length === 0) {
        this.filterCount = 0;
      }
    },
    searchCategory: {
      handler(newVal) {
        if (newVal && newVal.id > 0) {
          this.selectedCategory = newVal.id;
          if (this.isMobile) {
            this.$nextTick(() => {
              const element = this.$refs[`category-${newVal.id}`]?.[0];
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
              }
            });
          }
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.category-product-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  gap 48px
  width 100%

.proudct-filter-wrapper
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  gap 24px

.product-list-empty
  display flex
  align-items center
  justify-content center
  width 100%
  word-break break-all
  white-space pre-line

.product-filter-container
  width 1152px

.product-filter-item
  display flex
  align-items center
  gap 8px
  width 1152px

.product-list-wrapper
  display grid
  grid-template-columns repeat(4, 1fr)
  gap 48px 24px
  width 1152px

.filter-count
  display flex
  align-items center
  justify-content center
  height 100%
  padding 2px 5px
  border-radius 10px
  border 1px solid #fff
  background #242428
  color #fff
  font-size 10px
  font-weight 600
.category-slider
  width 100%
  overflow-x scroll
  overflow-y hidden
  border-bottom 1px solid #EEE
  -webkit-overflow-scrolling touch
  scroll-snap-type x mandatory

  &::-webkit-scrollbar
    display none

  scrollbar-width none

.stick-header-m
  position sticky
  top 59px
  z-index 2
  background #fff

.category-slider-wrapper-m
  display flex
  align-items center
  gap 8px
  background #fff

.category-slider-item-m
  display flex
  flex-direction column
  align-items center
  gap 4px
  flex 0 0 auto
  width fit-content
  padding 8px
  min-width 80px
  height 54px
  text-align center
  scroll-snap-align start
  cursor pointer

  &.selected
    padding 8px 8px 5px
    border-bottom 3px solid #242428

  img
    width 16px
    height 16px
    object-fit cover

  div
    font-size 12px
    white-space nowrap
    overflow hidden
    text-overflow ellipsis

@media (max-width: 425px)
  .proudct-filter-wrapper
    display flex
    flex-direction column
    align-items center
    justify-content center
    width 100%
    gap 24px
    padding 0 16px

  .product-filter-container
    width 100%

  .product-filter-item
    display flex
    align-items center
    gap 8px
    width 100%

  .product-list-wrapper
    display grid
    grid-template-columns repeat(1, 1fr)
    gap 24px
    width 100%
</style>
