<template>
  <div class="filter-list-container">
    <div class="filter-list-wrapper">
      <div class="flex-align unselect" style="gap: 4px" @click.stop="reset">
        <img src="/static/icon/u_redo.svg" style="width: 20px; height: 20px" alt="초기화" />
        <div class="body4-medium sub">초기화</div>
      </div>
      <div class="filter-item" v-for="(item, index) in list" :key="`${item.key}_${index}`">
        <div class="body5">{{ item.value }}</div>
        <i class="material-icons sub5 unselect" style="font-size: 16px" @click.stop="removeItem(item, index)">close</i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilteredList',
  props: {
    filtered: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {
    filtered: {
      immediate: true,
      handler() {
        this.updateList();
      },
    },
  },
  methods: {
    updateList() {
      this.list = [];
      this.filtered.forEach((item) => {
        if (item.value && Array.isArray(item.value)) {
          item.value.forEach((valueItem) => {
            this.list.push({
              key: item.key,
              value: valueItem,
            });
          });
        } else if (item.key === 'price') {
          this.list.push({
            key: item.key,
            value: `${this.currencyKor(item.start)}${this.minUnit(item.start)} ~ ${this.currencyKor(
              item.end
            )}${this.maxUnit(item.end)}`,
          });
        } else if (item.key === 'category') {
          let value = item.value.split(',');
          value.forEach((valueItem) => {
            this.list.push({
              key: item.key,
              value: this.getCategoryName(valueItem),
            });
          });
        }
      });
      this.$emit('listLength', this.list.length);
    },
    minUnit(value) {
      return parseInt(value) === 0 ? '' : ' 대';
    },
    maxUnit(value) {
      return parseInt(value) === 100000000 ? ' 이하' : ' 대';
    },
    getCategoryName(key) {
      const categories = {
        '59': '테마',
        '60': '스킨',
        '70': '소스코드 템플릿',
      };
      return categories[key];
    },
    reset() {
      this.list = [];
      this.$emit('listLength', 0);
      this.$emit('reset');
    },
    removeItem(item, index) {
      this.list.splice(index, 1);
      this.$emit('removeFilter', item);
      this.$emit('listLength', this.list.length);
    },
  },
};
</script>

<style lang="stylus" type="stylus" scoped>
.filter-list-container
  display flex
  align-items center
  width 1152px

.filter-list-wrapper
  display flex
  align-items center
  flex-wrap wrap
  gap 8px
  padding 12px
  background-color #F5F5F5
  border-radius 8px
  width 100%

.filter-item
  display flex
  align-items center
  justify-content space-between
  padding 4px 8px
  border 1px solid #E3E3E3
  border-radius 4px
  background-color #FFFFFF

@media (max-width: 425px)
  .filter-list-container
    width 100%
</style>
