<template>
  <div v-if="detail" class="product-filter-container">
    <i v-if="!isMobile" class="unselect material-icons close-btn" @click="close"> close </i>
    <sweet-modal ref="modal" overlay-theme="dark" hideCloseButton @close="handleClose">
      <div class="modal-container" ref="modalContainer">
        <div class="modal-wrapper">
          <div v-if="isMobile" class="close-btn-wrapper">
            <i class="unselect material-icons close-btn-m" @click="close"> close </i>
          </div>
          <div class="modal-header">
            <div class="header-title-wrapper">
              <div class="header-title" style="flex: 1">
                <div class="h7 main" style="word-break: keep-all; white-space: pre-line; text-align: left">
                  {{ detail.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="header-title-wrapper sticky-header">
            <div class="flex-column unselect" style="gap: 2px" @click="clickPartnerDetail">
              <div class="body4-medium" style="word-break: keep-all; white-space: pre-line; text-align: left">
                {{ detail.partner.nickname }}
              </div>
              <div class="flex-align body6 sub2" style="gap: 8px">
                <div>{{ businessTypeKor(detail.business_type) }}</div>
                <div class="line-bar"></div>
                <div>
                  템플릿 <span class="primary">{{ detail.partner.product_count | currencyNum }}개</span>
                </div>
              </div>
            </div>
            <div class="flex modal-header-buttons" style="gap: 8px">
              <div class="card-bookmark unselect" @click.stop="toggleBookmark">
                <img
                  :src="`/static/icon/u_bookmark-${isBookmarked ? 'on' : 'off'}.svg`"
                  style="width: 20px; height: 20px" />
              </div>
              <button
                class="button is-primary body2-bold"
                style="padding: 0px 12px; height: 44px; word-break: keep-all"
                @click="clickProdInquiry">
                {{ isMobile ? '제작문의하기' : '이 템플릿으로 제작문의하기' }}
              </button>
            </div>
          </div>
          <div class="modal-images">
            <template v-if="detail.imgs.length > 0">
              <img v-for="(img, idx) in detail.imgs" :src="img" :key="`img-${idx}`" />
            </template>
            <template v-else>
              <img :src="detail.img" />
            </template>
          </div>
        </div>
        <lp-footer v-if="isMobile"></lp-footer>
      </div>
    </sweet-modal>
  </div>
</template>

<script>
import { SweetModal } from 'sweet-modal-vue';
import LpFooter from '../component/LpFooter.vue';

export default {
  name: 'ScreenshotModal',
  mixins: [],
  components: {
    SweetModal,
    LpFooter,
  },
  props: {
    detail: {
      type: Object,
    },
  },
  data() {
    return {
      isBookmarked: false,
    };
  },
  mounted() {},
  computed: {},
  methods: {
    open() {
      this.$nextTick(() => {
        this.$refs.modal.open();
        document.documentElement.style.overflow = 'hidden';
        // ref를 사용하여 modal-container의 스크롤을 맨 위로 이동
        this.$nextTick(() => {
          if (this.$refs.modalContainer) {
            this.$refs.modalContainer.scrollTop = 0;
          }
        });
        this.setUrl();
      });
    },
    clickPartnerDetail() {
      this.routerPush(`/partner_detail?id=${this.detail.partner.id}`);
      this.close();
    },
    businessTypeKor(type) {
      switch (type) {
        case 0:
          return '법인사업자';
        case 1:
          return '개인사업자';
        case 2:
          return '팀';
        case 3:
          return '개인';
      }
    },
    setUrl() {
      if (this.isLogin) {
        this.isBookmarked = this.detail.is_liked;
      }
    },
    close() {
      document.documentElement.style.overflow = '';
      this.$refs.modal.close();
    },
    handleClose() {
      document.documentElement.style.overflow = '';
      this.$emit('close');
    },
    clickProdInquiry() {
      this.$emit('clickProdInquiry');
      this.close();
    },
    toggleBookmark() {
      if (!this.isLogin) {
        this.toast('북마크 하시려면 로그인이 필요합니다');
        this.$store.commit('setPreviousPath', this.$route.fullPath);
        this.routerPush('/signin');
        return;
      }
      let url = `user/${this.$store.getters.user.user_id}/product/${this.detail.id}/like`;

      this.$axios.post(url).then(() => {
        this.isBookmarked = !this.isBookmarked;
        this.$emit('updateBookmark', this.isBookmarked);
        if (this.isBookmarked) {
          this.toast('북마크 하였습니다.');
        }
      });
    },
  },
};
</script>

<style lang="stylus" scoped>
@import '~assets/css/lp_main'

.modal-container
  display flex
  flex-direction column
  height 90vh
  overflow-y auto
.modal-wrapper
  display flex
  flex-direction column
  padding 24px
.close-btn-wrapper
  display flex
  justify-content flex-end
  padding 12px 16px
  position fixed
  width 100%
  top 0
  left 0
  background-color white
  z-index 100
  border-bottom 1px solid $gray2
.modal-header
  display flex
  flex-direction column
  align-items flex-start
  gap 20px
  .header-title
    display flex
    flex-direction column
    align-items flex-start
    gap 8px
.header-title-wrapper
    display flex
    align-items flex-start
    justify-content space-between
    width 100%
    gap 8px
    flex 1
    &.sticky-header
      position sticky
      top 0
      background-color white
      z-index 100
      padding 16px 0px

.card-bookmark
  display flex
  align-items center
  justify-content center
  width 44px
  height 44px
  border-radius 8px
  border 1px solid $gray1
  background-color white
.modal-images
  display flex
  flex-direction column
  gap 8px
.close-btn
  position fixed
  top 40px
  right 40px
  transform none
  color white
  z-index 10000
  cursor pointer
  font-size 24px
.line-bar
  width 1px
  height 12px
  background-color $gray1

@media (max-width: 425px)
  .modal-container
    overflow-y auto
    height 100vh
  .modal-wrapper
    margin-top 48px
    padding 16px 16px
  .modal-header
    flex-direction column
    align-items flex-start
    gap 20px
  .header-title-wrapper
    display flex
    align-items flex-start
    justify-content space-between
    width 100%
    gap 8px
    flex 1
    &.sticky-header
      position sticky
      top 49px
      background-color white
      z-index 100
      padding 16px 0px
  .modal-images
    gap 16px

  .close-btn-m
    display block
</style>
<style lang="stylus">
.product-filter-container
  .sweet-modal .sweet-content
    padding 0 !important
  .sweet-modal-overlay
    -webkit-perspective initial
  .sweet-modal.is-alert
    width 660px
    max-width 660px
    overflow hidden
  .sweet-modal.is-alert .sweet-content
    padding 0
  @media (max-width: 425px)
    .sweet-modal
      border-radius 0 !important
    .sweet-modal.theme-light.has-title.has-content.is-mobile-fullscreen.is-visible
      overflow-y hidden
    .sweet-modal .sweet-title
      display flex
      align-items center
      padding 16px
      font-size 18px !important
      font-weight 700 !important
      line-height 24px !important
    .sweet-modal.is-alert
      width 100%
      max-width 100%
    .sweet-modal .sweet-box-actions
      top 10px
      right 10px
</style>
