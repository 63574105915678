import josa from 'josa-js';
import { isEmptyObj } from '../../../public/static/js/common';

function isValid(properties, label) {
  if (!properties.validate.required) {
    return '';
  }
  let msg = '';
  if (
    properties.validate.valid_required &&
    ['text', 'email', 'number', 'phone', 'password', 'editor', 'number-unit', 'dropdown'].indexOf(
      properties.component_name.replace('lp-', '')
    ) > -1
  ) {
    if (label === undefined || label === '') label = '항목';
    let patternNum = /[0-9]/; // 숫자
    let patternC = /[a-zA-Z]/; // 문자(대소문자)
    let patternS = /[a-z]/; // 소문자
    let patternB = /[A-Z]/; // 대문자
    let patternSpecial = /[~!@#$%^&*()_+|<>?:{}]/; // 특수문자
    let patternEmail = /[0-9a-zA-Z][_0-9a-zA-Z-]*@[_0-9a-zA-Z-]+(\.[_0-9a-zA-Z-]+){1,2}$/; //이메일

    // 빈문자 일 경우
    if (properties.value === '') {
      msg = label + josa.c(label, '을/를') + ' 입력해주세요.';
    }

    // 숫자인 경우
    else if (properties.type === 'number') {
      properties.value = Number(properties.value);
      // if (properties.value === 0)
      //   msg = label + josa.c(label, '을/를') + ' 입력해주세요.';

      if (properties.value < properties.validate.min_length)
        msg = label + josa.c(label, '을/를') + ' 최소 %d 이상 입력해주세요.'.format(properties.validate.min_length);
      else if (properties.value > properties.validate.max_length)
        msg = label + josa.c(label, '을/를') + ' 최대 %d 이하로 입력해주세요.'.format(properties.validate.max_length);
      else if (properties.value < properties.validate.min)
        msg = label + josa.c(label, '을/를') + ' 최소 %d 이상 입력해주세요.'.format(properties.validate.min);
      else if (properties.value > properties.validate.max)
        msg = label + josa.c(label, '을/를') + ' 최대 %d 이하로 입력해주세요.'.format(properties.validate.max);
    }

    // 텍스트인 경우
    else if (properties.type.search('text') > -1 && typeof properties.value === 'string') {
      // if(properties.value === '')
      //   msg = label + josa.c(label, '을/를') + ' 입력해주세요.';

      if (properties.value.length < properties.validate.min_length)
        msg = `(${properties.value.length}/${properties.validate.max_length}) ${label + josa.c(label, '을/를')} 최소 ${
          properties.validate.min_length
        }자 이상 입력해주세요.`;
      else if (properties.value.length > properties.validate.max_length)
        msg = `(${properties.value.length}/${properties.validate.max_length}) ${label + josa.c(label, '을/를')} 최대 ${
          properties.validate.max_length
        }자 이하로 입력해주세요.`;

      if (msg === '') {
        let msgVal = '';
        // 소문자 사용 안함
        if (properties.validate.alpha_s === false && patternS.test(properties.value)) msgVal = '알파벳 소문자';

        // 대문자 사용 안함
        if (properties.validate.alpha_g === false && patternB.test(properties.value)) {
          if (msgVal !== '') msgVal += ', ';
          msgVal += '알파벳 대문자';
        }
        // 특수문자 사용 안함
        if (properties.validate.special === false && patternSpecial.test(properties.value)) {
          if (msgVal !== '') msgVal += ', ';
          msgVal += '특수문자';
        }

        // 숫자 사용 안함
        if (properties.validate.num === false && patternNum.test(properties.value)) {
          if (msgVal !== '') msgVal += ', ';
          msgVal += '숫자';
        }
        if (msgVal !== '' && msgVal !== undefined) msg = msgVal + '는 사용할 수 없습니다.';
      }
    }

    // email
    else if (properties.type === 'email' && !patternEmail.test(properties.value)) {
      msg = '올바른 이메일 형식이 아닙니다.';
    }

    // 휴대폰번호
    else if (properties.type === 'phone' && properties.value.length < 11) {
      msg = label + josa.c(label, '을/를') + ' 정확히 입력해주세요.';
    } else if (properties.type === 'password') {
      if (!patternC.test(properties.value) || !patternNum.test(properties.value) || properties.value.length < 4) {
        msg = '비밀번호는 4자리 이상 문자, 숫자로 구성되어야 합니다.';
      }
    } else if (properties.type === 'password_new') {
      if (
        !patternC.test(properties.new_password) ||
        !patternNum.test(properties.new_password) ||
        properties.new_password.length < 4
      ) {
        msg = '비밀번호는 4자리 이상 문자, 숫자로 구성되어야 합니다.';
      }
    }
  } else {
    if (properties.component_name === 'lp-drop-down') {
      if (properties.validate.required && (isEmptyObj(properties.value) || properties.value === '')) {
        msg = label + josa.c(label, '을/를') + ' 선택해주세요.';
      }
    }
    // c-input-radiobuttonGroup
    else if (properties.component_name === 'lp-radio') {
      if (properties.validate.required && (properties.value === undefined || properties.value === '')) {
        msg = label + josa.c(label, '을/를') + ' 선택해주세요.';
      }
    } else if (['InputTemplateCategory', 'checkbox-group', 'input-tech'].indexOf(properties.component_name) > -1) {
      if (properties.validate.required && properties.value.length === 0) {
        msg = label + josa.c(label, '을/를') + ' 선택해주세요.';
      }
    } else if (properties.component_name === 'input-date-period') {
      if (
        (properties.validate.required && (properties.value.start === undefined || properties.value.start === '')) ||
        properties.value.end === undefined ||
        properties.value.end === ''
      ) {
        msg = label + josa.c(label, '을/를') + ' 선택해주세요.';
      }
    } else if (['input-price', 'input-rate'].indexOf(properties.component_name) > -1) {
      if (properties.validate.required && properties.value.price === 0) {
        msg = label + josa.c(label, '을/를') + ' 입력해주세요.';
      }
    } else if (properties.component_name === 'input-phone-verify') {
      if (properties.validate.required && !properties.validate.success) {
        msg = label + josa.c(label, '을/를') + ' 인증해주세요.';
      }
    } else if (properties.component_name === 'input-file') {
      if (properties.validate.required && properties.value.length === 0) {
        msg = label + josa.c(label, '을/를') + ' 첨부해주세요.';
      }
    } else if (properties.component_name === 'lp-check-box-array') {
      if (properties.validate.required && !properties.value) {
        msg = label + josa.c(label, '을/를') + ' 선택해주세요.';
      }
    }
  }
  // validation 필수
  if (properties.validate.valid_required) {
    // 빈값 체크
    if (
      properties.value === '' ||
      properties.value === undefined ||
      properties.value === null ||
      (Array.isArray(properties.value) && properties.value.length === 0)
    ) {
      if (['imgs', 'files'].indexOf(properties.type) > -1) {
        msg = label + josa.c(label, '을/를') + ' 첨부해주세요.';
      } else if (properties.type === 'dropdown') {
        msg = label + josa.c(label, '을/를') + ' 선택해주세요.';
      } else if (properties.type === 'check') {
        msg = label + josa.c(label, '을/를') + ' 체크해주세요.';
      } else {
        msg = label + josa.c(label, '을/를') + ' 입력해주세요.';
      }
    }
  }

  return msg;
}

export { isValid };
